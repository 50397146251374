import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import Layout from "src/components/new/Layout"
import Logo from "src/components/Logo"
import Content from "src/components/Content"

import "./thanksTemplate.scss"

const PageTemplate = ({data: {page: {frontmatter: {
  meta, hero: { image, title, subtitle }
}, html}}}) => {

  const pluginImage = getImage(image)

  return (
    <Layout meta={meta}>
      <div className="thanks-page">
        <BgImage
          className="page-hero"
          image={pluginImage}
          style={{ minWidth: "100%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <Logo isBlue={false} className="thanks-logo" />
                <h1>{title}</h1>
                <h1>{subtitle}</h1>
              </div>
            </div>
          </div>
        </BgImage>
        <div id="content" className="page-content container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Content html={html} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

PageTemplate.defaultProps = {
  data: {
    page: {
      frontmatter: {
        meta: {},
        hero: {},
      },
      html: "",
    },
  },
}

PageTemplate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.object,
        hero: PropTypes.object,
      }),
      html: PropTypes.string,
    }),
  }),
}

export default PageTemplate

export const pageQuery = graphql`
  query($path: String!) {
    page: markdownRemark(frontmatter: { meta: { path: { eq: $path } } }) {
      html
      ...MetaFragment
      ...HeroFragment
    }
  }
`
